import React from 'react';
import { Container, Typography, Card, CardContent, Grid, Box, Chip, Button } from '@mui/material';
import { motion } from 'framer-motion';

interface ServiceTierProps {
  title: string;
  price: string;
  features: string[];
  isPopular?: boolean;
}

interface Tier {
  title: string;
  price: string;
  features: string[];
  isPopular?: boolean;
}

const ServiceTier: React.FC<ServiceTierProps> = ({ title, price, features, isPopular = false }) => (
  <motion.div
    whileHover={{ y: -10 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
  >
    <Card 
      elevation={isPopular ? 8 : 3}
      sx={{
        height: '100%',
        position: 'relative',
        border: isPopular ? '2px solid #2196f3' : 'none'
      }}
    >
      {isPopular && (
        <Chip
          label="Most Popular"
          color="primary"
          sx={{
            position: 'absolute',
            top: -12,
            right: 20,
          }}
        />
      )}
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h4" color="primary" gutterBottom>
          ₹{price}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {features.map((feature: string, index: number) => (
            <Typography key={index} paragraph sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              • {feature}
            </Typography>
          ))}
        </Box>
        <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Get Started
        </Button>
      </CardContent>
    </Card>
  </motion.div>
);

const Services = () => {
  const tiers: Tier[] = [
    {
      title: "Basic Business Presence",
      price: "10,000",
      features: [
        "Static responsive website (3-5 pages)",
        "Mobile-friendly design",
        "Basic contact form",
        "WhatsApp integration",
        "Google Maps embedding",
        "Social media links",
        "Basic SEO optimization",
        "Basic Google Analytics",
        "Hosting setup (client pays hosting/domain)",
        "Development Time: 20-30 hours"
      ]
    },
    {
      title: "Professional Business Website",
      price: "25,000",
      features: [
        "Everything in Basic tier",
        "Up to 8-10 pages",
        "Custom responsive design",
        "Advanced contact forms",
        "Image gallery/portfolio",
        "Blog setup (static)",
        "Google Business integration",
        "Testimonial section",
        "Basic animations",
        "Service showcase section",
        "Development Time: 40-60 hours"
      ],
      isPopular: true
    },
    {
      title: "AI Training Services",
      price: "Custom",
      features: [
        "ChatGPT mastery training",
        "Claude AI advanced usage",
        "VS Code AI extensions",
        "Custom AI integration consulting",
        "One-on-one sessions",
        "Hands-on exercises",
        "Real-world applications",
        "Best practices & strategies",
        "Ongoing support",
        "Flexible scheduling"
      ]
    }
  ];

  return (
    <Container sx={{ py: 8 }}>
      <Typography variant="h3" component="h1" gutterBottom align="center">
        Services & Pricing
      </Typography>
      <Typography variant="h6" color="textSecondary" align="center" paragraph>
        Choose the perfect package for your business needs
      </Typography>
      
      <Grid container spacing={4} sx={{ mt: 4 }}>
        {tiers.map((tier, index) => (
          <Grid item key={index} xs={12} md={4}>
            <ServiceTier {...tier} />
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" gutterBottom align="center">
          Custom Solutions
        </Typography>
        <Typography align="center" paragraph>
          Need something specific? Contact me for a customized solution tailored to your unique requirements.
        </Typography>
      </Box>
    </Container>
  );
};

export default Services;
