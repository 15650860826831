import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Box, Button } from '@mui/material';
import { motion } from 'framer-motion';

const Training = () => {
  const courses = [
    {
      title: "ChatGPT Mastery",
      image: "https://source.unsplash.com/random/400x300?ai",
      description: "Learn to leverage ChatGPT for business automation, content creation, and problem-solving. Master prompt engineering and advanced usage techniques.",
      highlights: [
        "Prompt engineering techniques",
        "Business use cases",
        "Content generation strategies",
        "Integration with workflows"
      ]
    },
    {
      title: "Claude AI Expert",
      image: "https://source.unsplash.com/random/400x300?technology",
      description: "Discover the power of Claude AI for sophisticated tasks. Learn how to use Claude for complex analysis, coding, and business solutions.",
      highlights: [
        "Advanced prompting",
        "Code generation",
        "Document analysis",
        "Problem-solving techniques"
      ]
    },
    {
      title: "VS Code AI Extensions",
      image: "https://source.unsplash.com/random/400x300?coding",
      description: "Boost your coding productivity with AI-powered VS Code extensions. Learn to use GitHub Copilot, Tabnine, and other AI coding assistants.",
      highlights: [
        "GitHub Copilot mastery",
        "AI code completion",
        "Productivity techniques",
        "Best practices"
      ]
    }
  ];

  return (
    <Container sx={{ py: 8 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography variant="h3" component="h1" gutterBottom align="center">
          AI Training Programs
        </Typography>
        <Typography variant="h6" color="textSecondary" align="center" paragraph>
          Master the latest AI tools and stay ahead in the digital age
        </Typography>
      </motion.div>

      <Grid container spacing={4} sx={{ mt: 4 }}>
        {courses.map((course, index) => (
          <Grid item key={index} xs={12} md={4}>
            <motion.div
              whileHover={{ y: -10 }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2 }}
            >
              <Card sx={{ height: '100%' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={course.image}
                  alt={course.title}
                />
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {course.title}
                  </Typography>
                  <Typography paragraph color="textSecondary">
                    {course.description}
                  </Typography>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      Key Highlights:
                    </Typography>
                    {course.highlights.map((highlight, i) => (
                      <Typography key={i} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        • {highlight}
                      </Typography>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 3 }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Custom Training Programs
        </Typography>
        <Typography paragraph>
          Need a specialized training program for your team? Contact me to discuss your requirements
          and create a customized training solution.
        </Typography>
        <Button variant="outlined" color="primary" size="large">
          Request Custom Training
        </Button>
      </Box>
    </Container>
  );
};

export default Training;
