import React from 'react';
import { Container, Typography, Box, Paper, Grid } from '@mui/material';
import { motion } from 'framer-motion';

const Home = () => {
  return (
    <Container>
      <Box sx={{ mt: 8, mb: 4 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Typography variant="h2" component="h1" gutterBottom align="center">
            Transforming Ideas into Digital Reality
          </Typography>
          <Typography variant="h5" color="textSecondary" align="center" paragraph>
            Expert Web Development & AI Training Solutions
          </Typography>
        </motion.div>
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              Web Development Services
            </Typography>
            <Typography paragraph>
              From simple landing pages to complex web applications, I deliver tailored solutions
              that help your business thrive in the digital world. Choose from our flexible
              service tiers to find the perfect match for your needs.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              AI Training & Consultation
            </Typography>
            <Typography paragraph>
              Master the latest AI tools with expert training in ChatGPT, Claude AI, and VS Code
              AI extensions. Stay ahead of the curve with cutting-edge AI technology integration
              for your business.
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h4" gutterBottom align="center">
          Why Choose Me?
        </Typography>
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {[
            'Modern, responsive designs',
            'Expert AI integration',
            'Performance optimization',
            'Future-ready solutions',
            'Personalized approach',
            'Continuous support'
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Paper
                  elevation={2}
                  sx={{
                    p: 2,
                    textAlign: 'center',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Typography>{feature}</Typography>
                </Paper>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Home;
